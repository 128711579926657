<template>
  <div>
    <component :is="pageType" :page="store.getPage" />
  </div>
</template>

<script setup lang="ts">
const store: any = useDefaultStore();
const states = useStates();
const pageType = store.pageType;
</script>

<style lang="scss" scoped></style>
